<template>
  <div>
    <Dialog v-model:visible="dialog" :style="{width: '450px'}" header="Edit" :modal="true" class="p-fluid" @hide="closeDialog">
      <div class="field">
        <MultiSelect
          v-model="v$.selectedCompanies.$model" :options="companies" optionLabel="name" optionValue="id"
          placeholder="Select Countries" :filter="true"
          id="selected-companies" class="multiselect-custom"
          :class="{'p-invalid': v$.selectedCompanies.$invalid && submitted}"
        ></MultiSelect>

        <small
          v-if="(v$.selectedCompanies.$invalid && submitted) || v$.selectedCompanies.$pending.$response"
          class="p-error">{{ v$.selectedCompanies.required.$message.replace('Value', 'selectedCompanies') }}
        </small>
      </div>
      <template #footer>
        <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="dialog = false" />
        <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveTool(!v$.$invalid)" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import {required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import toolResource from "@http/ToolResource";
import companyResource from "@http/CompanyResource";
import toastParamBuilder from "@/builders/ToastParamBuilder";

export default {
  setup: () => ({v$: useVuelidate()}),

  emits: ["hideDialog"],

  created() {
    companyResource.allCompanies()
      .then(response => {
        this.companies = response.data;
      });

    if (this.$route.query?.select_companies) {
      this.loadEdit();
    }
  },

  data() {
    return {
      lazyItems: null,
      lazyLoading: false,
      dialog: false,
      selectedCompanies: null,
      companies: [],
      submitted: false,
    }
  },

  methods: {
    openNew() {
      this.selectedCompanies = null;
      this.submitted = false;
      this.dialog = true;
    },

    saveTool(isFormValid) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      }

      toolResource.publish(this.$route.query?.select_companies, {companies: this.selectedCompanies})
        .then(() => {
          this.$toast.add(toastParamBuilder.success('Published'));
          this.loading = true;
          this.closeDialog();
        })
        .catch(error => {
          this.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
        });
    },

    loadEdit() {
      this.submitted = false;
      this.dialog = true;
    },

    closeDialog() {
      this.submitted = false;
      this.dialog = false;
      this.selectedCompanies = null;
      let query = Object.assign({}, this.$route.query);
      delete query.select_companies;
      this.$router.push({
        name: this.$route.name,
        query
      });
    },
  },

  watch: {
    '$route.query': {
      handler: function (query) {
        if (query.select_companies) {
          this.loadEdit(query.select_companies);
        }
      }
    },
  },

  validations() {
    return {
      selectedCompanies: {required},
    }
  },
}
</script>
