<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <SelectCompanies/>
        <ConfirmPopup>
          <template #message="slotProps">
            <div class="flex p-4">
              <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
              <p class="pl-2">{{slotProps.message.message}}</p>
            </div>
          </template>
        </ConfirmPopup>

        <EditTools/>

        <div class="flex justify-content-between mb-4 table-label-search">
          <h3>New Tools</h3>
          <div>
            <template v-if="this.selectedTools?.length">
              <Button @click="selectedHide(false)" label="Unavailable" icon="pi pi-times" class="mr-2" />
            </template>

            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText v-model="search" placeholder="Keyword Search" @input="onSearch" />
            </span>
          </div>
        </div>

        <DataTable
          :value="getTools" :lazy="true" :paginator="true" :first="getPagination?.from-1"
          :rows="getPagination?.per_page ?? 25"  ref="dt" dataKey="id" :rowClass="rowClass"
          :totalRecords="getPagination.total" :loading="loading" @page="onPage($event)"
          @sort="onSort($event)" :sortField="sort" :sortOrder="order"
          responsiveLayout="scroll" :rowsPerPageOptions="[10,25,50]"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          :globalFilterFields="['category']" v-model:filters="filters" filterDisplay="row" @filter="onFilter($event, ['category', 'name', 'price', 'profit', 'sell'])"
          v-model:selection="selectedTools" :selectAll="selectAll" @select-all-change="onSelectAllChange" @row-select="onRowSelect" @row-unselect="onRowUnselect"
        >
          <template #empty>
            Not found.
          </template>

          <Column selectionMode="multiple" headerStyle="width: 3em"></Column>

          <!-- API Name -->
          <Column field="api_provider_name" header="API" :sortable="true" headerStyle="min-width: 100px;" />

          <!-- Tool Name -->
          <Column field="name" header="Tool Name" :sortable="true" :showFilterMenu="false">
            <template #filter="{filterModel, filterCallback}">
              <InputText type="text" v-model="filterModel.value" placeholder="Search" @input="filterCallback()"/>
            </template>
          </Column>

          <!-- Category Name -->
          <Column field="category" header="Category Name" :showFilterMenu="false" :sortable="true">
            <template #body="{data}">
              <span class="image-text">{{data.category}}</span>
            </template>
            <template #filter="{filterModel,filterCallback}">
              <MultiSelect
                v-model="filterModel.value" @change="filterCallback()" :options="categories"
                optionLabel="name" optionValue="id" placeholder="Any" class="p-column-filter"
                :showClear="true"
              >
                <template #option="slotProps">
                  <div class="p-multiselect-representative-option">
                    <span class="image-text">{{slotProps.option.name}}</span>
                  </div>
                </template>
              </MultiSelect>
            </template>
          </Column>

          <!-- API Price ($) -->
          <Column field="price" header="API Price ($)" :sortable="true"  :showFilterMenu="false">
            <template #filter="{filterModel, filterCallback}">
              <InputText type="text" v-model="filterModel.value" placeholder="Search" @input="filterCallback()"/>
            </template>
          </Column>

          <!-- Profit Price ($) -->
          <Column field="profit" header="Profit Price ($)" :sortable="true"  :showFilterMenu="false">
            <template #filter="{filterModel, filterCallback}">
              <InputText type="text" v-model="filterModel.value" placeholder="Search" @input="filterCallback()"/>
            </template>
          </Column>

          <!-- Sell Price ($) -->
          <Column field="sell" header="Sell Price ($)" :sortable="true"  :showFilterMenu="false">
          </Column>

          <!-- Actions -->
          <Column headerStyle="min-width:15rem;">
            <template #body="slotProps">
              <Button
                icon="pi pi-eye-slash" class="p-button-rounded p-button-danger mr-2"
                v-tooltip="'Hide'"
                @click="hide(slotProps.data.id)"
              />
              <Button
                icon="pi pi-tags" class="p-button-rounded p-button-warning mr-2"
                v-tooltip="'Set profit price'"
                @click="edit(slotProps.data.id)"
              />
              <Button
                icon="pi pi-check-circle" class="p-button-rounded p-button-success mr-2"
                v-tooltip="'Publish'"
                @click="handlePublish($event, slotProps.data.id)"
              />
            </template>
          </Column>

        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import store from "@store";
import toastParamBuilder from "@/builders/ToastParamBuilder";
import categoryResource from "@http/CategoryResource";
import {FilterMatchMode} from "primevue/api";
import EditTools from "@/components/tools/EditTools";
import SelectCompanies from "@/components/tools/SelectCompanies";
import toolResource from "@http/ToolResource";
import dataTable from "@/mixins/dataTable";

export default {
  mixins: [dataTable],
  components: {SelectCompanies, EditTools},
  data() {
    return {
      categories: [],
      selectedTools: null,
      selectAll: false,
      filters: {
        category: {value: null, matchMode: FilterMatchMode.EQUALS},
        name: {value: null, matchMode: FilterMatchMode.EQUALS},
        price: {value: null, matchMode: FilterMatchMode.EQUALS},
        profit: {value: null, matchMode: FilterMatchMode.EQUALS},
      },
    }
  },

  methods: {
    rowClass(data) {
      return [{ 'bg-new': data.is_new === 1 }];
    },
    ...mapActions({
      countNewToolsToday: 'tool/countNewToolsToday'
    }),
    hide(id) {
      toolResource.visibility(id, {is_visible: 0})
        .then(() => {
          this.countNewToolsToday();
          this.$router.push({
            name: this.$route.name,
            query: {
              ...this.$route.query,
              _r: Date.now(),
            }
          });
        })
        .catch(error => {
          this.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
        });
    },
    edit(id) {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          tool: id,
        }
      });
    },
    handlePublish(event, id) {
      this.$confirm.require({
        target: event.currentTarget,
        message: 'Publish to all companies or select?',
        acceptLabel: 'All',
        rejectLabel: 'Select',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          toolResource.publish(id, {})
            .then(() => {
              this.$toast.add(toastParamBuilder.success('Published'));
              this.countNewToolsToday();
              this.$router.push({
                name: this.$route.name,
                query: {
                  ...this.$route.query,
                  _r: Date.now(),
                }
              });
            });
        },
        reject: () => {
          this.$router.push({
            name: this.$route.name,
            query: {
              ...this.$route.query,
              select_companies: id,
            }
          });
        }
      });
    },

    selectedHide(is_visible) {
      let tool_ids = this.selectedTools?.map(item => item.id);
      toolResource.multipleVisible({tool_ids, is_visible})
        .then(() => {
          this.countNewToolsToday();
          this.$router.push({
            name: this.$route.name,
            query: {
              ...this.$route.query,
              _r: Date.now(),
            }
          });
        })
        .catch(error => {
          this.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
        });
    },

    onSelectAllChange(event) {
      const selectAll = event.checked;
      if (selectAll) {
        this.selectAll = true;
        this.selectedTools = this.getTools;
      } else {
        this.selectAll = false;
        this.selectedTools = [];
      }
    },
    onRowSelect() {
      this.selectAll = this.selectedTools.length === this.getTools.length;
    },
    onRowUnselect() {
      this.selectAll = false;
    }
  },

  computed: {
    ...mapGetters({
      getTools: "tool/getTools",
      getPagination: "tool/getPagination"
    }),
  },

  async beforeRouteEnter(to, from, next) {
    try {
      let categories = await categoryResource.categories();
      await store.dispatch('tool/countNewToolsToday');
      await store.dispatch('tool/newTools', to.query);
      next((vm) => {
        vm.loading = false;
        vm.categories = categories?.data;
        if (vm.$route.query?.category) {
          vm.filters.category.value = categories.data.filter((e) => {
            return vm.$route.query?.category.includes(e.id.toString());
          }).map(e => e.id);
        }
      });
    } catch (error) {
      next((vm) => {
        vm.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
      });
    }
  },

  async beforeRouteUpdate(to) {
    await store.dispatch('tool/newTools', to.query)
      .finally(() => {
        this.loading = false;
      });
  },
}
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

::v-deep(.p-datepicker) {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
</style>
<style>
  .p-sortable-column {
    white-space: nowrap;
  }
  .bg-new {
    background-color: #b3d9ff !important;
  }
</style>
