<template>
  <div>
    <Dialog v-model:visible="dialog" :style="{width: '450px'}" header="Edit" :modal="true" class="p-fluid" @hide="closeDialog">
      <!-- Tool Name -->
      <div class="field">
        <label>Original Name:</label>
        <p>{{form.original_name}}</p>
      </div>

      <!-- API Price -->
      <div class="field">
        <label>API Price:</label>
        <p v-if="form.api_provider_name !== 'CUSTOM TOOLS'">{{form.price}}</p>
        <div v-else>
          <InputText
              id="price" v-model.trim="v$.form.price.$model" required="true" autofocus
              :class="{'p-invalid': v$.form.price.$invalid && submitted}"
          />
          <small
              v-if="(v$.form.price.$invalid && submitted) || v$.form.price.$pending.$response"
              class="p-error">{{ v$.form.price.required.$message.replace('Value', 'Price') }}
          </small>
        </div>
      </div>

      <!-- Profit Price ($) -->
      <div class="field">
        <label for="tool_name">Tool Name*</label>
        <InputText
          id="tool_name" v-model.trim="v$.form.name.$model" required="true" autofocus
          :class="{'p-invalid': v$.form.name.$invalid && submitted}"
        />
        <small
          v-if="(v$.form.name.$invalid && submitted) || v$.form.name.$pending.$response"
          class="p-error">{{ v$.form.name.required.$message.replace('Value', 'Name') }}
        </small>
      </div>
      <div class="field">
        <label for="description">Description</label>
        <Editor v-model="form.description" editorStyle="height: 180px" id="description"/>
      </div>
      <div class="field">
        <label for="profit">Profit Price ($)*</label>
        <InputText
          id="profit" v-model.trim="v$.form.profit.$model" required="true" autofocus
          :class="{'p-invalid': v$.form.profit.$invalid && submitted}"
        />
        <small
          v-if="(v$.form.profit.$invalid && submitted) || v$.form.profit.$pending.$response"
          class="p-error">{{ v$.form.profit.required.$message.replace('Value', 'Profit Price') }}
        </small>
      </div>

      <template #footer>
        <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="dialog = false" />
        <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveTool(!v$.$invalid)" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import {required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import toastParamBuilder from "@/builders/ToastParamBuilder";
import toolResource from "@http/ToolResource";

export default {
  setup: () => ({v$: useVuelidate()}),

  emits: ["hideDialog"],

  created() {
    if (this.$route.query?.tool) {
      this.loadEdit(this.$route.query?.tool);
    }
  },

  data() {
    return {
      dialog: false,
      form: {
        id: null,
        name: '',
        price: 0,
        profit: 0,
        description: '',
        original_name: ''
      },
      submitted: false,
    }
  },

  methods: {
    openNew() {
      this.form = {};
      this.submitted = false;
      this.dialog = true;
    },

    saveTool(isFormValid) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      }

      toolResource.update(this.$route.query?.tool, this.form)
        .then(({data}) => {
          this.$toast.add(toastParamBuilder.success(data));
          this.loading = true;
          this.closeDialog();
        })
        .catch(error => {
          this.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
        });
    },

    loadEdit(id) {
      toolResource.tool(id)
        .then(({data}) => {
          this.form = data;
          this.submitted = false;
          this.dialog = true;
        });
    },

    closeDialog() {
      this.submitted = false;
      this.dialog = false;
      this.form = {};
      let query = Object.assign({}, this.$route.query);
      delete query.tool;
      this.$router.push({
        name: this.$route.name,
        query
      });
    },
  },

  watch: {
    '$route.query': {
      handler: function (query) {
        if (query.tool) {
          this.loadEdit(query.tool);
        }
      }
    },
  },

  validations() {
    return {
      form: {
        profit: {required},
        name: {required},
        price: {required},
      }
    }
  },
}
</script>
